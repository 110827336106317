export enum AppRoute {
  LogIn = '/',
  Dashboard = '/dashboard',
  Notifications = '/notifications',
  Admins = '/admins',
  Employees = '/employees',
  Config = '/config',
  SetPassword = '/set-password/:token',
  Auth0Callback = '/auth0-callback',
  ComplaintMailbox = '/complaint-mailbox',
  Authorizations = '/authorizations',
  ArchiveComplaintMailBox = '/complaint-mailbox/archived',
  Auth0Landing = '/auth0-landing',
}
