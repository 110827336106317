import { Dialog, DialogTitle, DialogContent, Skeleton, Button, Divider } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { CloseDialogButton } from '../../design/Button/CloseDialogButton';
import { ComplaintDTO, ComplaintTypeDTO } from '../../types/complaint.dto';
import { trimStringToMaxLength } from '../../utilities/i18n/helpers';
import { ComplaintPrioritySpanishEnum, ComplaintStatus, ComplaintStatusEnum } from '../../enums/complaint.enum';
import { StyledTextH2 } from '../../design/theme/shared-style';
import {
  BodyContainer, BodyText, CardInformationContainer,
  LabelInformation, LeftSection, RightSection,
  StandarInformation, UserItem, UserList, priorityStyles, titleStylesEnglish,
} from '../../views/ComplaintMailbox/complaintMailboxStyle';
import UserDropDown from './UserDropdown';
import { listUserEmployeesDropdown } from '../../api/user/user.api';
import {
  archiveComplaintBackend,
  asociateUser,
  deleteUserRelation,
  getComplaintCategories,
} from '../../api/complaint/complaint.api';
import { PrioritySelectorModal } from '../../views/ComplaintMailbox/PrioritySelectorModal';
import { toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TypeSelectorModal } from '../../views/ComplaintMailbox/TypeSelectorModal';
import { EditResolutionModal } from '../../views/ComplaintMailbox/EditResolution';
import ArchiveIcon from '@mui/icons-material/Archive';
import FilePreview from '../Shared/FilePreview/FilePreview';
import { getMimeTypeFromUrlFile } from '../../helpers/file.helpers';


export const ComplaintDetailModal: FC<{
  complaintToDetail: ComplaintDTO | null;
  reloadComplaints: () => Promise<void>;
  onClose: () => void;
  open: boolean;
}> = ({ open, complaintToDetail, onClose, reloadComplaints }) => {
  const StatusComponent = complaintToDetail?.status ? titleStylesEnglish[complaintToDetail?.status] : StandarInformation;
  const PriorityComponent = complaintToDetail?.priority ? priorityStyles[complaintToDetail?.priority] : StandarInformation;
  const [users, setUsers] = useState<any[]>([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [prioritySelectorOpen, setPrioritySelectorOpen] = useState(false);
  const [typeSelectorOpen, setTypeSelectorOpen] = useState(false);
  const [editResolutionOpen, setEditResolutionOpen] = useState(false);
  const [complaintSelected, setComplaintSelected] = useState(complaintToDetail);
  const [categories, setCategories] = useState<ComplaintTypeDTO[]>([]);
  const [closeDate, setCloseDate] = useState<Date | null>(null);

  const loadUsersDropwdown = async (): Promise<void> => {
    const users = await listUserEmployeesDropdown();
    if (users && users.length > 0) {
      setUsers(users);
    }
  };

  const loadCategoriesDropwdown = async (): Promise<void> => {
    const complaintCategories = await getComplaintCategories();
    setCategories(complaintCategories);
  };

  const setUser = async (selectedUserId: string) => {
    try {
      setLoadingUser(true);
      if (complaintSelected?.id) {
        toast.info('Asociando usuario...', {
          position: toast.POSITION.TOP_CENTER,
        });
        const updatedComplaint = await asociateUser({
          complaintId: complaintSelected?.id,
          userId: selectedUserId,
        });
        reloadComplaints();
        setComplaintSelected(updatedComplaint);
        setLoadingUser(false);
        toast.success('Usuario asociado correctamente', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (err: any) {
      setLoadingUser(false);
      toast.error('Ha ocurrido un error, intenta nuevamente', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const loadSavedComplaint = (complaint: ComplaintDTO) => {
    setComplaintSelected(complaint);
  };

  const deleteUser = async (userId: string) => {
    try {
      if (complaintSelected) {
        setLoadingUser(true);
        const updatedComplaint = await deleteUserRelation({
          complaintId: complaintSelected?.id,
          userId: userId,
        });
        reloadComplaints();
        setComplaintSelected(updatedComplaint);
        setLoadingUser(false);
      }
    } catch (err: any) {
      setLoadingUser(false);
      toast.error('Ha ocurrido un error, intenta nuevamente', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const loadCloseData = () => {
    if(complaintToDetail && complaintToDetail.statusDates && complaintToDetail.statusDates.length > 0) {
      const mostRecetStatus = complaintToDetail.statusDates
        .find(statusDate => statusDate.status == ComplaintStatusEnum.CLOSE);
      if(complaintToDetail.status === ComplaintStatusEnum.CLOSE && mostRecetStatus) {
        setCloseDate(mostRecetStatus.date);
      }
    }
  }

  useEffect(() => {
    loadUsersDropwdown();
    loadCategoriesDropwdown();
  }, []);

  useEffect(() => {
    setComplaintSelected(complaintToDetail);
    loadCloseData();
  }, [complaintToDetail]);

  const archiveComplaint = async () => {
    try {
      toast.info('Archivando denuncia', {
        position: toast.POSITION.TOP_CENTER,
      });
      await archiveComplaintBackend(complaintToDetail?.id || '');
      toast.dismiss();
      toast.success('Denuncia archivada. Si necesitas, puedes revisarla en la sección de denuncias archivadas.', {
        position: toast.POSITION.TOP_CENTER,
      });
      onClose();
      await reloadComplaints();
    } catch (error) {
      toast.dismiss();
      toast.error('Ha ocurrido un error, intenta nuevamente', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left">
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LeftSection>
            <StyledTextH2>{complaintSelected?.title}</StyledTextH2>
            <BodyContainer>
              <CardInformationContainer>
                <div>
                  <LabelInformation>
                    Denunciante:
                  </LabelInformation>
                  <LabelInformation>
                    <StandarInformation>
                      {complaintSelected?.anonymous ? 'Anónimo' :
                        trimStringToMaxLength(
                          `${complaintSelected?.user?.name} ${complaintSelected?.user?.firstLastname}`,
                        )}
                    </StandarInformation>
                  </LabelInformation>
                  <LabelInformation>
                    Categoría:
                  </LabelInformation>
                  <div onClick={() => setTypeSelectorOpen(true)} style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                    <StandarInformation>
                      {complaintSelected?.type?.name}
                    </StandarInformation>
                    <EditIcon style={{ fontSize: '20px', marginLeft: '5px' }} />
                  </div>
                </div>
                <div>
                  <LabelInformation>
                    Nombre denunciado:
                  </LabelInformation>
                  <div style={{ marginRight: '5px', marginBottom: '10px' }}>
                    <StandarInformation>
                      {complaintSelected?.reportedUsersEntry || 'Sin información'}
                    </StandarInformation>
                  </div>
                  <LabelInformation>
                    {closeDate ?
                      'Fecha de creación - cierre:' : 'Fecha de creación:'
                    }
                  </LabelInformation>
                  <div>
                    <StandarInformation>
                      {complaintSelected?.statusDates[0].date &&
                        new Date(complaintSelected?.statusDates[0].date).toLocaleDateString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}
                    </StandarInformation>
                    {closeDate &&
                      <>
                        ‎ - ‎
                        <StandarInformation>
                          {complaintSelected?.statusDates[0].date &&
                            new Date(closeDate).toLocaleDateString('es-ES', {
                              day: '2-digit',
                              month: '2-digit',
                              year: 'numeric',
                            })}
                        </StandarInformation>
                      </>
                    }
                  </div>
                </div>
                <div>
                  <LabelInformation>
                    Estado:
                  </LabelInformation>
                  <div style={{ marginBottom: '10px' }}>
                    <StatusComponent>
                      {complaintSelected?.status ? ComplaintStatus[complaintSelected?.status] : ''}
                    </StatusComponent>
                  </div>
                  <LabelInformation>
                    Prioridad:
                  </LabelInformation>
                  <div onClick={() => setPrioritySelectorOpen(true)} style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <PriorityComponent>
                      {complaintSelected?.priority ?
                        ComplaintPrioritySpanishEnum[complaintSelected?.priority] : 'Sin establecer'}
                    </PriorityComponent>
                    <EditIcon style={{ fontSize: '20px', marginLeft: '5px' }} />
                  </div>
                </div>
              </CardInformationContainer>
            </BodyContainer>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <Button
                  startIcon={<ArchiveIcon />}
                  variant="outlined"
                  style={{ height: '36px', marginTop: '15px' }}
                  onClick={archiveComplaint}
                >
                  Archivar denuncia
                </Button>
              </div>
              {complaintSelected?.atachmentFile &&
                <div>
                  <LabelInformation style={{ color: '#7d7d7d', marginTop: '15px' }}>Archivo adjunto:</LabelInformation>
                  <FilePreview fileUrl={complaintSelected?.atachmentFile}
                               fileType={getMimeTypeFromUrlFile(complaintSelected?.atachmentFile)}></FilePreview>
                </div>
              }
            </div>
            <LabelInformation style={{ color: '#7d7d7d', marginTop: '15px' }}>Descripción:</LabelInformation>
            <BodyContainer style={{ marginTop: '0px' }}>
              <BodyText>{complaintSelected?.body}</BodyText>
            </BodyContainer>
            <LabelInformation style={{ marginTop: '15px', color: '#7d7d7d' }}>Resolución:</LabelInformation>
            <BodyContainer onClick={() => setEditResolutionOpen(true)} style={{ marginTop: '0px', cursor: 'pointer' }}>
              <BodyText>
                {
                  complaintSelected?.bodyResolution ?
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p>{complaintSelected?.bodyResolution}</p>
                      <EditIcon style={{ fontSize: '20px', marginLeft: '5px' }} />
                    </div>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', color: '#7d7d7d' }}>
                      <p>No se ha establecido una resolución.</p>
                      <EditIcon style={{ fontSize: '20px', marginLeft: '5px' }} />
                    </div>
                }
              </BodyText>
            </BodyContainer>
          </LeftSection>
          <RightSection>
            <div>
              <StyledTextH2 style={{
                fontSize: '1.1rem',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              }}>
                Usuarios denunciados
              </StyledTextH2>
              <UserDropDown users={users} setUser={setUser}></UserDropDown>
              {loadingUser ? (
                <Skeleton style={{ height: '100px' }} />
              ) : (
                complaintSelected?.usersReported.length &&
                complaintSelected?.usersReported.length > 0 ? (
                  <UserList>
                    {complaintSelected?.usersReported.map((user) => (
                      <>
                        <div
                          key={user.id}
                          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                          <UserItem>
                            <PersonIcon style={{ marginRight: '8px' }} />
                            {`${user.name} ${user.firstLastname}`}
                          </UserItem>
                          <DeleteIcon style={{
                            cursor: 'pointer',
                            color: '#d9534f',
                            padding: '2px 2px',
                            background: 'rgba(217, 83, 79, 0.15)',
                            borderRadius: '5px',
                          }}
                                      onClick={() => deleteUser(user.id)} />
                        </div>
                        <Divider />
                      </>
                    ))}
                  </UserList>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '26px' }}>
                    <p
                      style={{
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      }}
                    >
                      No asociados
                    </p>
                  </div>
                )
              )}
            </div>
          </RightSection>
        </div>
      </DialogContent>
      <PrioritySelectorModal
        open={prioritySelectorOpen}
        onClose={() => setPrioritySelectorOpen(false)}
        complaintToDetail={complaintSelected}
        reloadComplaints={reloadComplaints}
        loadSavedComplaint={loadSavedComplaint}
      />
      <TypeSelectorModal
        open={typeSelectorOpen}
        onClose={() => setTypeSelectorOpen(false)}
        complaintToDetail={complaintSelected}
        reloadComplaints={reloadComplaints}
        loadSavedComplaint={loadSavedComplaint}
        categories={categories}
      />
      <EditResolutionModal
        open={editResolutionOpen}
        onClose={() => setEditResolutionOpen(false)}
        complaintToDetail={complaintSelected}
        reloadComplaints={reloadComplaints}
        loadSavedComplaint={loadSavedComplaint}
      />
    </Dialog>
  );
};
