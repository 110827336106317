/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'utilities/http/http';
import { Notifications } from '../../types/notifications.dto';
const baseUrl = '/notifications';

const stopScheduleUrl = (notificationId: string) => `${baseUrl}/stopSchedule/${notificationId}`;

export async function listNotifications(
    filterByCompany?: string
): Promise<Array<Notifications>> {
    try {
        const response = await http.get<{
            success: boolean;
            data: Array<Notifications>;
        }>(`/notifications/listNotifications/${filterByCompany}`);
        if (response.success) {
            return response.data;
        } else {
            throw new Error('Error List Notifications');
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function editNotifications(
    editNotificationData: {
        notificationBody: string,
        notificationTitle: string,
        notificationId: string
    }
): Promise<boolean> {
    try {
        const formatNewData = {
            notificationBody: editNotificationData.notificationBody,
            notificationTitle: editNotificationData.notificationTitle
        }
        const response = await http.put<{
            success: boolean;
            data: boolean;
        }>(`/notifications/${editNotificationData.notificationId}`, formatNewData);
        if (response.success) {
            return response.success;
        } else {
            throw new Error('Error Edit Notifications');
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function deleteNotification(notificationId: string): Promise<boolean> {
    try {
        const response = await http.delete<{
            success: boolean;
            data: boolean;
        }>(`/notifications/${notificationId}`);
        if (response.success) {
            return response.success;
        } else {
            throw new Error('Error Delete Notifications');
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function stopSchedule(notificationId: string): Promise<boolean> {
    try {
        const response = await http.put<{
            success: boolean;
            data: boolean;
        }>(stopScheduleUrl(notificationId));
        if (response.success) {
            return response.success;
        } else {
            throw new Error('Error Stoping Schedule');
        }
    } catch (error: any) {
        throw new Error(error.message);
    }
}

