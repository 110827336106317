import {
  AutoGraph,
  Business, Close,
  EmojiPeopleOutlined,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  NotificationImportant,
  PeopleAltOutlined, Search,
} from '@mui/icons-material';
import {
  AppBar,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  styled, TextField, InputAdornment, Grow,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import { MenuIcon } from 'design/Icon/MenuIcon';
import { SettingsIcon } from 'design/Icon/SettingsIcon';
import { Paragraph } from 'design/Text/Paragraph';
import { palette, violetTheme } from 'design/theme/palette';
import { AppRoute } from 'enums/app-route.enum';
import { removeAuthTokenAction, removeLoggedUser } from 'global-state/actions';
import { selectUser } from 'global-state/selectors';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserRoleEnum } from '../../types/user-role.enum';
import { navItems } from './nav-items';
import { useAuth } from '../../auth/AuthContext';
import { LoginType } from '../../enums/user-enum';
import { getClientInformation } from '../../api/client/client.api';
import { storage } from '../../helpers/storage.helpers';
import { StorageItem } from '../../enums/storage-item.enum';
import Skeleton from 'react-loading-skeleton';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { SettingsOutlined } from '@mui/icons-material/';

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

type Props = {
  drawerWidth: number;
};

const mapMenuTabs: { [key: string]: number } = {
  [AppRoute.Dashboard]: 0,
  [AppRoute.Admins]: 1,
  [AppRoute.Employees]: 2,
  [AppRoute.Notifications]: 3,
  [AppRoute.Config]: 4,
};

const TabContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
}));

const CollapseContainer = styled('div')(({ theme }) => ({
  overflowY: 'auto',
}));

interface Contract {
  createdAt: string;
  deletedAt: string | null;
  id: string;
  specialCondition: string | null;
  startDate: string;
  status: string;
  term: string;
  updatedAt: string;
}

interface Profile {
  createdAt: string;
  deletedAt: string | null;
  description: string;
  id: string;
  name: string;
  updatedAt: string;
}

interface ContractData {
  activeContract: Contract | null;
  relatedProfiles: Profile[] | null;
}

export interface CompanyList {
  complaintsEnable: boolean;
  contractData: ContractData;
  entityName: string;
  id: string;
  notificationsEnable: boolean;
}

export const SideNavBar: FC<Props> = ({ drawerWidth }) => {
  const loggedUser = useSelector(selectUser);
  const { logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [companies, setCompanies] = useState<null | CompanyList[]>(null);
  const [originalCompaniesList, setoriginalCompaniesList] = useState<null | CompanyList[]>(null);
  const [clientName, setClientName] = useState('');
  const [entityName, setEntityName] = useState('');
  const [companySelected, setCompanySelected] = useState<null | CompanyList>(null);
  const [activeMenuTab, setActiveMenuTab] = useState(
    mapMenuTabs[window.location.pathname]
      ? mapMenuTabs[window.location.pathname]
      : 0,
  );
  const [preferencesMenu, setPreferencesMenu] = useState(null);
  const [empresasMenuOpen, setEmpresasMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectCompany = (company: CompanyList) => {
    setCompanySelected(company);
    storage.session.set(StorageItem.COMPANY_SELECTED, company);
    navigate(navItems[0].to);
    toggleDrawer();
    window.location.reload();
  };
  const toggleDrawer = () => {
    if (drawerOpen) {
      setEmpresasMenuOpen(false);
    }
    setDrawerOpen(!drawerOpen);
  };
  const loadCompanyStorange = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        const companySelectedStorange = storage.session.get(StorageItem.COMPANY_SELECTED);
        if (!companySelectedStorange && loggedUser) {
          let companyDefault: CompanyList | undefined;
          if (loggedUser.role === UserRoleEnum.COMPANY) {
            companyDefault = companies?.find((company) => {
              return company.entityName === loggedUser.companyName;
            });
          } else if (loggedUser.role === UserRoleEnum.ADMIN) {
            companyDefault = {
              entityName: 'Administrador Twispi',
              complaintsEnable: false,
              notificationsEnable: true,
              id: '0',
              contractData: {
                activeContract: null,
                relatedProfiles: null,
              },
            };
          }
          if (companyDefault) {
            storage.session.set(StorageItem.COMPANY_SELECTED, companyDefault);
            setCompanySelected(companyDefault);
          }
        } else {
          setCompanySelected(companySelectedStorange as any);
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleTabChange = (
    event: React.SyntheticEvent,
    selectedTab: number,
  ) => {
    event.preventDefault();
    setActiveMenuTab(selectedTab);
    toggleDrawer();
  };
  const loadClientInformation = async () => {
    await loadCompanyStorange();
    const clientNameStorange = storage.session.get(StorageItem.CLIENT_NAME);
    const companyInfoStorange = storage.session.get(StorageItem.COMPANY_SELECTED);
    if (clientNameStorange) {
      setClientName(clientNameStorange as string);
    }
    const clientInfo = await getClientInformation();
    setCompanies(clientInfo.companies);
    setoriginalCompaniesList(clientInfo.companies);
    setClientName(clientInfo.clientName);
    setEntityName(loggedUser?.virtualizedDashboard ? loggedUser.location : (companyInfoStorange as any)?.entityName);
    storage.session.set(StorageItem.CLIENT_NAME, clientInfo.clientName);
  };
  const handleLogout = () => {
    if (loggedUser && (loggedUser as any).loginType === LoginType.AUTH0) {
      logout();
    }
    setPreferencesMenu(null);
    dispatch(removeAuthTokenAction());
    dispatch(removeLoggedUser());
    sessionStorage.removeItem(StorageItem.COMPANY_SELECTED);
    sessionStorage.removeItem(StorageItem.CLIENT_NAME);
  };
  const openPreferencesMenu = (e: any) => {
    setPreferencesMenu(e.currentTarget);
  };
  const closePreferencesMenu = () => {
    setPreferencesMenu(null);
  };
  const handleToggleEmpresasMenu = () => {
    setEmpresasMenuOpen(!empresasMenuOpen);
  };

  useEffect(() => {
    if (mapMenuTabs[window.location.pathname] !== 0) {
      setActiveMenuTab(mapMenuTabs[window.location.pathname] - 1);
    }
    loadClientInformation().then(() => setIsLoading(false));
  }, []);
  useEffect(() => {
    loadCompanyStorange();
  }, [companies]);
  useEffect(() => {
      setEntityName((loggedUser?.virtualizedDashboard ? loggedUser.location : companySelected?.entityName) || '');
  }, [companySelected]);
  useEffect(() => {
    if (!searchTerm) {
      setCompanies(originalCompaniesList);
    } else if (originalCompaniesList) {
      setCompanies(null);
      setTimeout(() => {
        const filteredList = originalCompaniesList.filter(company =>
          company.entityName.toUpperCase().includes(searchTerm.toUpperCase()),
        );
        setCompanies(filteredList);
      }, 100);
    }
  }, [searchTerm]);

  return (
    <ThemeProvider theme={violetTheme}>
      <AppBar position="fixed" color="primary">
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={toggleDrawer} id="navbar-button">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ display: 'flex', alignItems: 'center' }}>
            {loggedUser?.name} {' - '}
            {isLoading ? <Skeleton style={{
              width: '100px',
              height: '27px',
              backgroundColor: '#70026d',
              marginLeft: '10px',
              marginBottom: '5px',
            }} /> : loggedUser?.role === 'ADMIN'
              ? 'Administrador Twispi'
              : clientName}{' '}
            <Business style={{ marginLeft: '10px', marginRight: '10px' }} />
          </Typography>
          <Typography variant="h6" noWrap style={{ display: 'flex', alignItems: 'center' }}>
            {isLoading ? <Skeleton style={{
              width: '130px',
              height: '27px',
              backgroundColor: '#70026d',
              marginLeft: '10px',
              marginBottom: '5px',
            }} /> : entityName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          display: loggedUser ? 'block' : 'none',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#70026d',
            boxShadow: '3px 0 5px 5px rgb(0 0 0 / 15%)',
            flexDirection: 'column',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: palette.white.main,
            left: 0,
            width: '4px',
          },
          '& .Mui-selected': {
            color: `${palette.white.main} !important`,
            background: `#9a0595 !important`,
          },
        }}
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Toolbar />
        <Divider />
        <TabContainer>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeMenuTab}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {loggedUser?.role === UserRoleEnum.ADMIN && (
              <Tab
                icon={<PeopleAltOutlined style={{ marginRight: '11%' }} />}
                iconPosition="start"
                key={1}
                label="Administradores"
                {...a11yProps(1)}
                style={{
                  fontSize: '14px',
                  color: palette.white.main,
                  justifyContent: 'flex-start',
                }}
                onClick={() => navigate(navItems[1].to)}
              />
            )}
            {companySelected && ((loggedUser?.role === UserRoleEnum.COMPANY)
              || (loggedUser?.role === UserRoleEnum.ADMIN && companySelected.id !== '0')) && (
              <Tab
                icon={<AutoGraph style={{ marginRight: '11%' }} />}
                iconPosition="start"
                key={0}
                label="Panel de control"
                {...a11yProps(0)}
                style={{
                  fontSize: '14px',
                  color: palette.white.main,
                  justifyContent: 'flex-start',
                }}
                onClick={() => navigate(navItems[0].to)}
              />
            )}
            <Tab
              icon={<EmojiPeopleOutlined style={{ marginRight: '11%' }} />}
              iconPosition="start"
              key={2}
              label="Colaboradores"
              {...a11yProps(2)}
              style={{
                fontSize: '14px',
                color: palette.white.main,
                justifyContent: 'flex-start',
              }}
              onClick={() => navigate(navItems[2].to)}
            />
            {companySelected?.notificationsEnable && !loggedUser?.virtualizedDashboard && (
              <Tab
                icon={<NotificationImportant style={{ marginRight: '11%' }} />}
                iconPosition="start"
                key={3}
                label="Notificaciones"
                {...a11yProps(3)}
                style={{
                  fontSize: '14px',
                  color: palette.white.main,
                  justifyContent: 'flex-start',
                }}
                onClick={() => navigate(navItems[3].to)}
              />
            )}
            {loggedUser?.role === UserRoleEnum.ADMIN && (
              <Tab
                icon={<SettingsOutlined style={{ marginRight: '11%' }} />}
                iconPosition="start"
                key={4}
                label="Configuración"
                {...a11yProps(4)}
                style={{
                  fontSize: '14px',
                  color: palette.white.main,
                  justifyContent: 'flex-start',
                }}
                onClick={() => navigate(navItems[4].to)}
              />
            )}
            {loggedUser?.role === UserRoleEnum.COMPANY && !loggedUser?.virtualizedDashboard && companySelected?.complaintsEnable && (
              <Tab
                icon={<MarkunreadMailboxIcon style={{ marginRight: '11%' }} />}
                iconPosition="start"
                key={5}
                label="Buzón de denuncias"
                {...a11yProps(5)}
                style={{
                  fontSize: '14px',
                  color: palette.white.main,
                  justifyContent: 'flex-start',
                }}
                onClick={() => navigate(navItems[5].to)}
              />
            )}
            {companySelected && loggedUser?.role === UserRoleEnum.COMPANY && !loggedUser?.virtualizedDashboard && loggedUser.authorizer && (
              <Tab
                icon={<VpnKeyIcon style={{ marginRight: '11%' }} />}
                iconPosition="start"
                key={6}
                label="Autorizaciones"
                {...a11yProps(6)}
                style={{
                  fontSize: '14px',
                  color: palette.white.main,
                  justifyContent: 'flex-start',
                }}
                onClick={() => navigate(navItems[6].to)}
              />
            )}
          </Tabs>
        </TabContainer>
        <Divider />
        {(loggedUser?.multiCompanyEnable || loggedUser?.role === UserRoleEnum.ADMIN) && !loggedUser.virtualizedDashboard &&
          <ListItem button onClick={handleToggleEmpresasMenu}>
            <ListItemIcon>
              <Business sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Empresas" sx={{ color: 'white' }} />
            {empresasMenuOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
          </ListItem>
        }
        <div style={{ flex: 1, overflow: 'auto' }}>
          <CollapseContainer>
            <Collapse in={empresasMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextField
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Buscar empresa"
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ color: '#D3D3D3' }}>
                          {searchTerm ? (
                            <IconButton onClick={(e) => setSearchTerm('')} style={{ padding: '0' }}>
                              <Close style={{ color: '#D3D3D3' }} />
                            </IconButton>
                          ) : (
                            <Search style={{ color: '#D3D3D3' }} />
                          )}
                        </InputAdornment>
                      ),
                      style: { color: '#D3D3D3' },
                    }}
                    inputProps={{
                      style: { color: '#D3D3D3' },
                    }}
                    sx={{
                      '& .MuiInput-underline:before': {
                        borderBottom: '1px solid #D3D3D3',
                      },
                      '& .MuiInput-underline:hover:before': {
                        borderBottom: '1px solid #D3D3D3',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottom: '1px solid #D3D3D3',
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: '#D3D3D3',
                        opacity: 1,
                      },
                    }}
                  />
                </div>
                {loggedUser?.role === UserRoleEnum.COMPANY && !searchTerm &&
                  <ListItem button key="ALL" sx={{ pl: 4 }} onClick={() => handleSelectCompany({
                    entityName: 'Todas las empresas',
                    complaintsEnable: false,
                    notificationsEnable: false,
                    id: '0',
                    contractData: {
                      activeContract: null,
                      relatedProfiles: null,
                    },
                  })}>
                    <ListItemText primary="Todas las empresas" sx={{ color: 'white' }} />
                  </ListItem>}
                {companies && companies.map((company) => (
                  <Grow in={true} timeout={700} key={company.id}>
                    <ListItem button key={company.id} sx={{ pl: 4 }} onClick={() => handleSelectCompany(company)}>
                      <ListItemText primary={company.entityName} sx={{ color: 'white' }} />
                    </ListItem>
                  </Grow>
                ))}
              </List>
            </Collapse>
          </CollapseContainer>
        </div>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={false}
          sx={{
            marginTop: 'auto',
            width: drawerWidth,
          }}
        >
          <div style={{ width: '100%' }}>
            <Divider />
            <Paragraph
              style={{
                fontSize: '14px',
                color: palette.white.main,
                textAlign: 'center',
                margin: 'auto',
                marginTop: '5px',
              }}
            >
              {loggedUser?.name}
              <IconButton
                size="small"
                sx={{ ml: 2 }}
                style={{
                  color: palette.white.main,
                }}
                onClick={openPreferencesMenu}
              >
                <SettingsIcon />
              </IconButton>
            </Paragraph>
          </div>
        </Tabs>
        <Menu
          sx={{ ml: 4 }}
          anchorEl={preferencesMenu}
          keepMounted
          open={Boolean(preferencesMenu)}
          onClose={closePreferencesMenu}
        >
          <MenuItem onClick={handleLogout} sx={{ color: '#d32f2f' }}>
            <ListItemIcon>
              <ExitToApp color="error" />
            </ListItemIcon>
            Salir
          </MenuItem>
        </Menu>
      </Drawer>
    </ThemeProvider>
  );
};

export default SideNavBar;
