import { Box, Divider, IconButton, InputAdornment, ThemeProvider, Typography } from '@mui/material';
import { login } from 'api/user/user.api';
import logo from 'assets/logo-full-white.png';
import { Form } from 'components/Form/Form';
import { toast } from 'components/Toast/Toast';
import { Button, Card, TextInput } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { setAuthTokenAction, setLoggedUserAction } from 'global-state/actions';
import { selectAuthToken, selectUser } from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { Align } from 'layout';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FcDefaultProps } from 'types/fc-default-props';
import { UserRoleEnum } from 'types/user-role.enum';
import * as Yup from 'yup';
import { violetTheme } from '../../design/theme/palette';
import { useAuth } from '../../auth/AuthContext';
import logoAuth from 'assets/auth0-icon.png';
import { VisibilityOff, Visibility } from '@mui/icons-material';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Debe ser un email válido')
    .required('El campo es requerido'),
});

type FormValues = {
  email: string;
  password: string;
};

export const Login: FC<FcDefaultProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const loggedUser = useSelector(selectUser);
  const [isAutocomplete, setIsAutocomplete] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const { loginWithRedirect } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      const inputRef = document.querySelectorAll('input[type="email"]:-webkit-autofill');
      setIsAutocomplete(inputRef.length > 0);
    }, 600);
  }, []);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    try {
      const { user, token } = await login(values.email, values.password);

      dispatch(setAuthTokenAction(token));
      dispatch(setLoggedUserAction(user));

      helpers.resetForm();
    } catch (error) {
      genericErrorHandler(error);
      const invalidCredentials = (error instanceof Error && error.message.includes('Invalid credentials'));
      if (invalidCredentials) {
        toast.error(
          'Credenciales inválidas',
          { position: 'top-center' },
        );
      } else {
        toast.error(
          'Algo salió mal. Por favor, inténtalo nuevamente más tarde.',
          { position: 'top-center' },
        );
      }
    }
  };

  useEffect(() => {
    if (authToken && loggedUser) {
      if (loggedUser.role === UserRoleEnum.ADMIN) {
        navigate(AppRoute.Admins);
      } else {
        navigate(AppRoute.Dashboard);
      }
    }
  }, [authToken, location.state, navigate]);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="80vh"
      flexDirection="column"
    >
      <img
        src={logo}
        alt="Logo Tewispi"
        style={{ width: '100%', maxWidth: '400px', marginBottom: '30px' }}
      />
      <Card.Base>
        <Form
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <>
              <Card.Body>
                <Align
                  column
                  gap={1}
                  style={{
                    minWidth: '340px',
                  }}
                >
                  <TextInput
                    label='Correo electrónico'
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.errors.email}
                    variant="outlined"
                    shrinkLabel={isAutocomplete}
                    fullWidth
                  />
                  <TextInput
                    label="Contraseña"
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.errors.password}
                    variant="outlined"
                    shrinkLabel={isAutocomplete}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleToggleShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Divider variant="middle" flexItem />
                  <ThemeProvider theme={violetTheme}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="filled"
                      fullWidth
                    >
                      Iniciar sesión
                    </Button>
{/*                    <Divider variant="middle" flexItem />
                    <Button
                      color="secondary"
                      variant="filled"
                      fullWidth
                      onClick={() => loginWithRedirect()}>Iniciar sesión con Auth0
                      <img
                        src={logoAuth}
                        alt="Icono personalizado"
                        style={{ marginLeft: '8px', height: '24px', width: '24px' }}
                      />
                    </Button>*/}
                  </ThemeProvider>
                </Align>
              </Card.Body>
            </>
          )}
        </Form>
      </Card.Base>
      <Typography
        noWrap
        color="white"
        textAlign={'center'}
        fontSize={'11px'}
        marginTop={'3px'}
      >
        V 2.3.4
      </Typography>
    </Box>
  );
};
