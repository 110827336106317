import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Modal from '@mui/material/Modal';
import { Button, IconButton, Typography } from '@mui/material';
import { Close, Fullscreen, Download} from '@mui/icons-material';
import './FilePreview.css';
import { firstLetterUppercase, trimStringToMaxLength } from '../../../utilities/i18n/helpers';

interface FilePreviewProps {
  fileUrl: string;
  fileType: string;
}

const workerUrl = 'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js';
const officePreviewUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=';
const typeExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const FilePreview: React.FC<FilePreviewProps> = ({ fileUrl, fileType }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [fileBlob, setFileBlob] = useState<string | null>(null);
  const [excelData, setExcelData] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileName, setFileName] = useState("archivo");

  const loadFile = async () => {
    try {
      const response = await fetch(fileUrl);
      const responseClone = response.clone();
      const blob = await response.blob();
      setFileBlob(URL.createObjectURL(blob));
      setFileName(firstLetterUppercase(trimStringToMaxLength(fileUrl.split('/').pop()?.replaceAll('%20', ' ') || '')));
      if (fileType === typeExcel) {
        const arrayBuffer = await responseClone.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const html = XLSX.utils.sheet_to_html(worksheet, {
          id: 'excel-preview',
          header: '',
          editable: false,
        });
        setExcelData(html);
      }
    } catch (error) {
      console.error('Error al descargar o procesar el archivo:', error);
    }
  };

  useEffect(() => {
    loadFile();
    return () => {
      if (fileBlob) {
        URL.revokeObjectURL(fileBlob);
      }
    };
  }, [fileUrl, fileType]);

  const handleMaximize = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderLittlePreview = () => {
    if (!fileBlob) return <div>Cargando...</div>;
    if (fileType.includes('image')) {
      return <img src={fileBlob} alt="Vista previa" className="thumbnail" />;
    } else if (fileType.includes('pdf')) {
      return (
        <div className="thumbnail" style={{ height: '300px', overflow: 'hidden',
            left: '-20%', top:'-5px', position: 'absolute', width: '120%' }}>
          <Worker workerUrl={workerUrl}>
            <Viewer fileUrl={fileBlob} defaultScale={0.8} plugins={[]} />
          </Worker>
        </div>
      );
    } else if (fileType === typeExcel && excelData) {
      return (
        <div
          className="thumbnail excel-thumbnail"
          dangerouslySetInnerHTML={{ __html: excelData }}
          style={{ maxHeight: '150px', overflow: 'hidden' }}
        />
      );
    } else if (fileType.includes('word') || fileType.includes('presentation')) {
      const officeViewerURL = `${officePreviewUrl}${encodeURIComponent(fileUrl)}`;
      return (
        <div style={{ width: '100%', height: '150px', overflow: 'hidden', position: 'relative' }}>
          <iframe
            src={officeViewerURL}
            width="180%"
            height="280px"
            className="iframe"
            title="Vista previa del documento"
          ></iframe>
        </div>
      );
    } else {
      return <div className="generic-icon">📄</div>;
    }
  };

  const loadFullScreenPreview = () => {
    if (!fileBlob) return <div>Cargando...</div>;
    if (fileType.includes('image')) {
      return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <img src={fileBlob} alt="Vista previa completa" style={{ width: '100%', height: 'auto', maxWidth: '300px' }} />
      </div>);
    } else if (fileType.includes('pdf')) {
      return (
        <div style={{ height: '100%' }}>
          <Worker workerUrl={workerUrl}>
            <Viewer fileUrl={fileBlob} plugins={[]} />
          </Worker>
        </div>
      );
    } else if (fileType === typeExcel && excelData) {
      const officeViewerURL = `${officePreviewUrl}${encodeURIComponent(fileUrl)}`;
      return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <iframe
            src={officeViewerURL}
            width="100%"
            height="100%"
            style={{
              border: 'none',
              position: 'absolute',
              top: '-15px',
              clipPath: 'inset(0 0 30px 0)'
            }}
            title="Vista completa del documento"
          ></iframe>
        </div>
      );
    } else if (fileType.includes('word') || fileType.includes('presentation')) {
      const officeViewerURL = `${officePreviewUrl}${encodeURIComponent(fileUrl)}`;
      return (
        <div style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
          <iframe
            src={officeViewerURL}
            width="126%"
            height="100%"
            style={{
              border: 'none',
              position: 'absolute',
              top: '-15px',
              left: '-13%',
            }}
            title="Vista completa del documento"
          ></iframe>
        </div>
      );
    }
  };

  const downloadFile = () => {
    if (fileBlob) {
      const link = document.createElement('a');
      link.href = fileBlob;
      link.download = fileUrl.split('/').pop() || 'archivo';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <div
        className="file-preview"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleMaximize}
      >
        {renderLittlePreview()}
        {isHovered && (
          <div className="overlay">
            <div className="overlay-buttons">
              <Button
                style={{
                  color: 'white',
                  background: '#555',
                  minWidth: '40px',
                  padding: '8px',
                }}
                size="small"
                variant="contained"
                onClick={handleMaximize}
              >
                <Fullscreen style={{ fontSize: '18px' }} />
              </Button>
              <Button
                size="small"
                style={{
                  color: 'white',
                  background: '#555',
                  minWidth: '40px',
                  padding: '8px',
                }}
                variant="contained"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  downloadFile();
                }}
              >
                <Download style={{ fontSize: '18px' }} />
              </Button>
            </div>
            <p className="overlay-title">
              {trimStringToMaxLength(fileName, 15)}
            </p>
          </div>
        )}
      </div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="full-preview-container">
          <IconButton
            style={{ position: 'absolute', top: 10, right: 10 }}
            onClick={handleCloseModal}
          >
            <Close />
          </IconButton>
          <Typography variant="h6" style={{ marginBottom: '20px' }}>
            {fileName}
          </Typography>
          {loadFullScreenPreview()}
        </div>
      </Modal>
    </>
  );
};

export default FilePreview;