import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  getClickByService,
  getClicksChart,
  getCompanyHeadCount,
  getCompanyHeadCountByAge, getConsultingScore,
  getCoveredEmployees,
  getDeactivatedByMonth,
  getEmployeesByArea,
  getEnrolledEmployees,
  getLoginsByMonth, getLoginsVirtualizedData,
  getRegisterByMonth,
} from 'api/company/company.api';
import appImage from 'assets/app.png';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import { firstLetterUppercase } from '../../utilities/i18n/helpers';
import { MonthsSpanish } from '../../enums/months.enum';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CompanyHeadCountByAgeDTO } from '../../types/company-head-count-by-age.dto';
import { ClickByServiceResponseDTO } from '../../types/click-by-service-response.dto';
import { MonthCounterResponseDTO } from '../../types/month-counter-response.dto';
import { EmployeeByAreaResponseDTO } from '../../types/employee-by-area-response.dto';
import { SeniorityRangeResponseDTO } from '../../types/seniority-range-response.dto';
import { getSalaryOnDemandData } from '../../api/transaction/transaction.api';
import { SalaryOnDemandReportDTO } from '../../types/salary_ondemand_report.dto';
import { RegisterByMonthDTO } from '../../types/register-by-month.dto';
import { DeactivatedByMonthDTO } from '../../types/deactivated-by-month.dto';
import {
  backgroundColors,
  backgroundColorsChart,
  backgroundColorsTransparent,
  optionsByMonthChart,
  optionsByAreaChart,
  optionsServiceChart,
  optionsDonutChart,
  optionsEmployeeChart,
  optionsRegisterEmployeesByMonth,
  optionsDeactivatedEmployeesByMonth,
  optionsPieChart,
  optionsEmployeeByAge,
  totalAmountMoneyChart,
  transactionAmountChart,
  optionsConsultingChart,
  optionsPieChartSpecialities,
  specialitiesChart,
  optionsChartReasonsLastMonth,
} from './chartjs-config';
import { getHealthDataService } from '../../api/health/health.api';
import Skeleton from 'react-loading-skeleton';
import { StarScoreDashboard } from './Components/StarScoreDashboard';
import { ReasonsLastTwelveMonthsDashboard } from './Components/ReasonsLastTwelveMonthsDashboard';
import { getRandomColorSolid, getRandomColorTransparent } from './utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../global-state/selectors';


// Styled components
const RootBox = styled(Box)`
    flex-grow: 1;
    padding: 0;
    margin: 0;
    text-align: center;
`;

const StyledCard = styled(Card)`
    margin: 2;
    text-align: center;
`;

const LinearProgressStyled = styled(LinearProgress)`
    margin: 3;
`;

const StyledChartCardBox = styled(Card)`
    margin: 2px;
    text-align: center;
    padding: 40px;
    box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const StyledUpperChartCardBox = styled(Card)`
    text-align: center;
    box-shadow: none !important;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;

const ChartTitle = styled(Typography)`
    margin: 0 auto 0 auto;
    margin-bottom: 30px !important;
    color: rgba(76, 86, 115, 255);
    font-weight: 500 !important;
`;

const StyledChartContainer = styled(Container)`
    max-width: 1200px !important;
    display: flex;
    align-items: center;
    margin-top: 10;
    margin-bottom: 3;
    width: 100%;
`;

export const Dashboard: FC = () => {
  const [loginByMonth, setLoginByMonth] = useState<MonthCounterResponseDTO[]>([]);
  const [coveredEmployees, setCoveredEmployees] = useState(0);
  const [enrolledEmployees, setEnrolledEmployees] = useState(0);
  const [loading, setLoading] = useState(true);
  const [employeesByArea, setEmployeesByArea] = useState<EmployeeByAreaResponseDTO[]>([]);
  const [registerByMonths, setRegisterByMonths] = useState<RegisterByMonthDTO[]>([]);
  const [deactivatedByMonths, setDeactivatedByMonths] = useState<DeactivatedByMonthDTO[]>([]);
  const [salaryOnDemandDataLoaded, setSalaryOnDemandDataLoaded] = useState<SalaryOnDemandReportDTO[]>([]);
  const [healthDataLoaded, setHealthDataLoaded] = useState<any>(null);
  const loggedUser = useSelector(selectUser);
  const [transactionAmountDataChart, setTransactionAmountDataChart] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: string[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [specialityCountDataChart, setSpecialityCountDataChart] = useState<any>(null);
  const [moneyAmountDataChart, seMoneyAmountDataChart] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [clicksChart, setClicksChart] = useState<MonthCounterResponseDTO[]>([]);
  const [clicksChartData, setClicksChartData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: string[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [clicksByService, setClicksByService] = useState<ClickByServiceResponseDTO[]>([]);
  const [scoreInformation, setScoreInformation] = useState<{ averageScore: number } | null>(null);
  const [clicksByServiceData, setClicksByServiceData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: string[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  } | null>(null);
  const [companyHeadCount, setCompanyHeadCount] = useState<MonthCounterResponseDTO[]>([]);
  const [companyHeadCountData, setCompanyHeadCountData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: string[];
      backgroundColor: string;
      borderColor: string;
      stack: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [lastMonthLogins, setLastMonthLogins] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [companyHeadCountByAge, setCompanyHeadCountByAge] = useState<SeniorityRangeResponseDTO[]>([]);
  const [companyHeadCountByAgeData, setCompanyHeadCountByAgeData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: string[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [registerByMonthData, setRegisterByMonthData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [deactivatedByMonthsData, setDeactivatedByMonthsData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      stack: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [dataMonthly, setDataMonthly] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: string[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [dataPie, setDataPie] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  } | null>(null);
  const [dataPieSpecialities, setDataPieSpecialities] = useState<any>(null);
  const [loginByYear, setLoginByYear] = useState<number>(0);
  const [loginByYearTotals, setLoginByYearTotals] = useState<number>(0);
  const [dataByArea, setDataByArea] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      stack: string;
      borderWidth: number;
      maxBarThickness: number;
      borderRadius: number;
    }[];
  } | null>(null);
  const [dataCountConsultingReason, setDataCountConsultingReason] = useState<any>(null);
  const [dataSpecialitiesLastMonth, setDataSpecialitiesLastMonth] = useState<any>(null);


  const loginsByMonthData = async () => {
    const data: any = await getLoginsByMonth();
    const currentMonthIndex = new Date().getMonth();
    const previousMonthIndex =
      currentMonthIndex === 0 ? currentMonthIndex : currentMonthIndex - 1;
    setLastMonthLogins(data[previousMonthIndex]?.counterUnique ? parseInt(data[previousMonthIndex]?.counterUnique) : 0);
    setLoginByMonth(data);
    const totalLoginsUnique = data.reduce((accumulator: any, currentValue: any) => {
      return accumulator + currentValue.counterUnique;
    }, 0);
    setLoginByYear(totalLoginsUnique);
    setLoginByYearTotals(data[previousMonthIndex].counterTotal);
  };

  const loginsVirtualizedData = async () => {
    const data = await getLoginsVirtualizedData();
    setLastMonthLogins(data.monthCount);
    setLoginByYear(data.yearCount);
  };

  useEffect(() => {
    if (!loggedUser?.virtualizedDashboard) {
      loginsByMonthData();
    } else {
      loginsVirtualizedData();
    }
  }, []);

  useEffect(() => {
    if (loginByMonth.length > 0) {
      const chartData = {
        labels: loginByMonth.map((dataPoint) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])),
        datasets: [
          {
            label: 'Cantidad de inicios de sesión',
            data: loginByMonth.map((dataPoint: any) => dataPoint.counterTotal),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setDataMonthly(chartData);
    }
  }, [loginByMonth]);

  useEffect(() => {
    if (salaryOnDemandDataLoaded.length > 0) {
      const chartDataTransactions = {
        labels: salaryOnDemandDataLoaded.map((dataPoint) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])),
        datasets: [
          {
            label: 'Cantidad de transacciones',
            data: salaryOnDemandDataLoaded.map((dataPoint) => dataPoint.count),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      const chartDataMoney = {
        labels: salaryOnDemandDataLoaded.map((dataPoint) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])),
        datasets: [
          {
            label: 'Cantidad de dinero',
            data: salaryOnDemandDataLoaded.map((dataPoint) => dataPoint.subtotal),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setTransactionAmountDataChart(chartDataTransactions);
      seMoneyAmountDataChart(chartDataMoney);
    }
  }, [salaryOnDemandDataLoaded]);

  useEffect(() => {
    if (registerByMonths.length > 0) {
      const chartData = {
        labels: registerByMonths.map((dataPoint: RegisterByMonthDTO) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])),
        datasets: [
          {
            label: 'Cantidad de registros nuevos',
            data: registerByMonths.map((dataPoint: RegisterByMonthDTO) => dataPoint.count),
            fill: false,
            backgroundColor: 'rgba(219,242,242,255)',
            borderColor: '#7acbbd',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setRegisterByMonthData(chartData);
    }
  }, [registerByMonths]);

  useEffect(() => {
    if (deactivatedByMonths.length > 0) {
      const labels = deactivatedByMonths.map((dataPoint) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish]));
      const chartData = {
        labels,
        datasets: [
          {
            label: 'Bajas voluntarias',
            data: deactivatedByMonths.map((dataPoint) => dataPoint.counterVoluntary),
            backgroundColor: 'rgba(219,242,242,255)',
            borderColor: '#7acbbd',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Bajas involuntarias',
            data: deactivatedByMonths.map((dataPoint) => dataPoint.counterInvoluntary),
            backgroundColor: 'rgba(255,224,230,255)',
            borderColor: '#ff859e',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Otras razones',
            data: deactivatedByMonths.map((dataPoint) => dataPoint.counterNull),
            backgroundColor: 'rgba(204,229,255,255)',
            borderColor: '#7daed4',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setDeactivatedByMonthsData(chartData);
    }
  }, [deactivatedByMonths]);

  useEffect(() => {
    if (clicksByService.length > 0) {
      const labels = clicksByService.map((data: ClickByServiceResponseDTO) => data.service);
      const dataValuesTotal = clicksByService.map((data: ClickByServiceResponseDTO) => data.counter);
      const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
      const dataDonutChart = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad de veces que se utilizó',
            data: dataValuesTotal,
            backgroundColor: backgroundColors.slice(0, labels.length),
            borderColor: borderColors.slice(0, labels.length),
            borderWidth: 1,
          },
        ],
      };
      setClicksByServiceData(dataDonutChart);
    }
  }, [clicksByService]);

  useEffect(() => {
    if (companyHeadCount.length > 0) {
      const labels = companyHeadCount.map((dataPoint) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish]));

      const chartData = {
        labels,
        datasets: [
          {
            label: 'Colaboradores activos',
            data: companyHeadCount.map((dataPoint) => dataPoint.counterActive),
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Colaboradores pendientes',
            data: companyHeadCount.map((dataPoint) => dataPoint.counterPending),
            backgroundColor: 'rgba(219,242,242,255)',
            borderColor: '#7acbbd',
            stack: 'combined',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setCompanyHeadCountData(chartData);
    }
  }, [companyHeadCount]);

  useEffect(() => {
    if (companyHeadCountByAge.length > 0) {
      const chartData = {
        labels: companyHeadCountByAge.map((dataPoint: CompanyHeadCountByAgeDTO) => dataPoint.seniorityRange),
        datasets: [
          {
            label: 'Cantidad de colaboradores',
            data: companyHeadCountByAge.map((dataPoint: CompanyHeadCountByAgeDTO) => dataPoint.counter),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setCompanyHeadCountByAgeData(chartData);
    }
  }, [companyHeadCountByAge]);

  useEffect(() => {
    if (employeesByArea.length > 0) {
      const labels = employeesByArea.map((employee) => employee.area);
      const dataValuesTotal = employeesByArea.map((employee) => parseInt(employee.total));
      const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
      const dataPieChart = {
        labels: labels,
        datasets: [
          {
            label: 'Usuarios',
            data: dataValuesTotal,
            backgroundColor: backgroundColors.slice(0, labels.length),
            borderColor: borderColors.slice(0, labels.length),
            borderWidth: 1,
          },
        ],
      };
      const dataByArea = {
        labels,
        datasets: [
          {
            label: 'Con inicio de sesión',
            data: employeesByArea.map((employee) => parseInt(employee.withLogin)),
            backgroundColor: 'rgba(219,242,242,255)',
            borderColor: '#7acbbd',
            stack: 'Stack 0',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
          {
            label: 'Sin inicio de sesión',
            data: employeesByArea.map((employee) => parseInt(employee.withOutLogin)),
            backgroundColor: 'rgba(255,224,230,255)',
            borderColor: '#ff859e',
            stack: 'Stack 1',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setDataPie(dataPieChart);
      setDataByArea(dataByArea);
    }
  }, [employeesByArea]);

  useEffect(() => {
    if (clicksChart.length > 0) {
      const chartData = {
        labels: clicksChart.map((dataPoint) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])),
        datasets: [
          {
            label: 'Servicios utilizados',
            data: clicksChart.map((dataPoint) => dataPoint.counter),
            fill: false,
            backgroundColor: 'rgba(235,224,255,255)',
            borderColor: '#b590ff',
            borderWidth: 2,
            maxBarThickness: 50,
            borderRadius: 10,
          },
        ],
      };
      setClicksChartData(chartData);
    }
  }, [clicksChart]);

  useEffect(() => {
    switch (tabValue) {
      case 0:
        getActivityData();
        break;
      case 1:
        getActivityMobileData();
        break;
      case 2:
        getDemographicData();
        break;
      case 3:
        getSalaryData();
        break;
      case 4:
        getHealthData();
        break;
    }
  }, [tabValue]);

  const getSalaryData = async () => {
    setLoading(true);
    await salaryOnDemandData();
    setLoading(false);
  };

  const getHealthData = async () => {
    setLoading(true);
    await healthData();
    setLoading(false);
  };

  useEffect(() => {
    if (healthDataLoaded?.cie10?.countByMonth?.length > 0) {
      loadCountByMonth();
    }
    if (healthDataLoaded?.cie10?.countConsultingReasonLastMonth?.length > 0) {
      loadCountConsultingReasonLastMonth();
    }
    if (healthDataLoaded?.specialty?.consultingReasonSpeciality?.length > 0) {
      loadConsultingReasonSpeciality();
    }
  }, [healthDataLoaded]);

  function loadCountByMonth() {
    const cie10CountByMonth = healthDataLoaded.cie10.countByMonth;
    const dataCountConsultingReason = {
      labels: cie10CountByMonth.map((dataPoint: any) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])),
      datasets: [
        {
          label: 'Consultas realizadas',
          data: cie10CountByMonth.map((dataPoint: any) => dataPoint.counter),
          fill: false,
          backgroundColor: 'rgba(235,224,255,255)',
          borderColor: '#b590ff',
          borderWidth: 2,
          maxBarThickness: 50,
          borderRadius: 10,
        },
      ],
    };
    setDataCountConsultingReason(dataCountConsultingReason);
  }

  function loadCountConsultingReasonLastMonth() {
    const consultingReasonSpecialityArray = healthDataLoaded.cie10.countConsultingReasonLastMonth;
    const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
    const labels = consultingReasonSpecialityArray.map((data: any) => {
      const labelCode = data.consulting_reason.split('-')[0];
      const labelName = data.consulting_reason.split('-')[1];
      return (labelCode ? labelCode : '') + ' - ' + (labelName ? firstLetterUppercase(labelName) : data.consulting_reason);
    });
    const dataValuesTotal = consultingReasonSpecialityArray.map((data: any) => data.counter);
    const dataSpecialityLastMonth = {
      labels: labels,
      datasets: [
        {
          label: 'Consultas realizadas',
          data: dataValuesTotal,
          backgroundColor: backgroundColors.slice(0, labels.length),
          borderColor: borderColors.slice(0, labels.length),
          borderWidth: 1,
        },
      ],
    };
    setDataSpecialitiesLastMonth(dataSpecialityLastMonth);
  }

  function loadConsultingReasonSpeciality() {
    const consultingData = healthDataLoaded.specialty.consultingReasonSpeciality;
    const indexLastMonth = healthDataLoaded?.specialty?.consultingReasonSpeciality?.length - 2;
    const specialitiesLastMonth = healthDataLoaded?.specialty?.consultingReasonSpeciality[indexLastMonth];
    const labels = specialitiesLastMonth.consultingCount.map((data: any) => firstLetterUppercase(data.reason));
    const dataValuesTotal = specialitiesLastMonth.consultingCount.map((data: any) => parseInt(data.count));
    const borderColors = backgroundColors.map((color) => color.replace('0.2', '1'));
    const chartDataMoney = {
      labels: consultingData.map((dataPoint: any) => firstLetterUppercase(MonthsSpanish[dataPoint.month as keyof typeof MonthsSpanish])),
      datasets: consultingData[0].consultingCount.map((reasonData: any, index: number) => {
        const colorSolid = getRandomColorSolid(index);
        const colorTransparent = getRandomColorTransparent(index);
        const datasetData = consultingData.map((monthData: any) => {
          const countData = monthData.consultingCount.find((item: any) => item.reason === reasonData.reason);
          return countData ? countData.count : 0;
        });
        const average = datasetData.reduce((sum: number, value: number) => sum + value, 0) / datasetData.length;

        return {
          label: firstLetterUppercase(reasonData.reason),
          data: datasetData,
          fill: true,
          backgroundColor: colorTransparent,
          borderColor: colorSolid,
          borderWidth: 2,
          maxBarThickness: 50,
          borderRadius: 10,
          average: average,
        };
      })
        .sort((a: any, b: any) => a.average - b.average),
    };
    chartDataMoney.datasets.forEach((dataset: any) => {
      delete dataset.average;
    });
    const dataPieChart = {
      labels: labels,
      datasets: [
        {
          label: 'Consultas',
          data: dataValuesTotal,
          backgroundColor: backgroundColors.slice(0, labels.length),
          borderColor: borderColors.slice(0, labels.length),
          borderWidth: 1,
        },
      ],
    };
    setSpecialityCountDataChart(chartDataMoney);
    if (
      dataPieChart.datasets.length === 1 &&
      dataPieChart.datasets[0].data &&
      dataPieChart.datasets[0].data.every((value: number) => value === 0)
    ) {
      setDataPieSpecialities(null);
    } else {
      setDataPieSpecialities(dataPieChart);
    }
  }

  const healthData = async () => {
    const data = await getHealthDataService();
    setHealthDataLoaded(data);
  };

  const coveredEmployeesData = async () => {
    const data = await getCoveredEmployees();
    setCoveredEmployees(data);
  };

  const enrolledEmployeesData = async () => {
    const data = await getEnrolledEmployees();
    setEnrolledEmployees(data);
  };

  const employeesByAreaData = async () => {
    const data = await getEmployeesByArea();
    setEmployeesByArea(data);
  };

  const salaryOnDemandData = async () => {
    const data = await getSalaryOnDemandData();
    setSalaryOnDemandDataLoaded(data);
  };

  const getClicksChartData = async () => {
    const data = await getClicksChart();
    setClicksChart(data);
  };

  const getRegisterChartData = async () => {
    const data = await getRegisterByMonth();
    setRegisterByMonths(data);
  };

  const getDeactivatedChartData = async () => {
    const data = await getDeactivatedByMonth();
    setDeactivatedByMonths(data);
  };

  const clicksByServiceDataLoad = async () => {
    const data = await getClickByService();
    setClicksByService(data);
  };

  const getScores = async () => {
    const data = await getConsultingScore();
    setScoreInformation(data);
  };

  const getCompanyHeadCountData = async () => {
    const data = await getCompanyHeadCount();
    setCompanyHeadCount(data);
  };

  const getCompanyHeadCountByAgeData = async () => {
    const data = await getCompanyHeadCountByAge();
    setCompanyHeadCountByAge(data);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getActivityData = async () => {
    setLoading(true);
    if (!loggedUser?.virtualizedDashboard) {
      await loginsByMonthData();
    } else {
      await loginsVirtualizedData();
    }
    await coveredEmployeesData();
    await enrolledEmployeesData();
    await employeesByAreaData();
    setLoading(false);
  };

  const getActivityMobileData = async () => {
    setLoading(true);
    await getClicksChartData();
    await clicksByServiceDataLoad();
    await getScores();
    setLoading(false);
  };

  const getDemographicData = async () => {
    setLoading(true);
    await getCompanyHeadCountData();
    await getRegisterChartData();
    await getDeactivatedChartData();
    await employeesByAreaData();
    await getCompanyHeadCountByAgeData();
    setLoading(false);
  };

  let lastMonthPeriod = firstLetterUppercase(MonthsSpanish[format(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    'LLL',
    {
      locale: es,
    },
  ) as keyof typeof MonthsSpanish]);

  if (lastMonthPeriod.toLocaleLowerCase() == MonthsSpanish.dic) {
    lastMonthPeriod = firstLetterUppercase(MonthsSpanish.ene);
  }

  return (
    <RootBox>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: '#bdc3c7',
          marginBottom: '30px',
          background: '#ecf0f1',
          padding: '20px',
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="Dashboard tabs"
          indicatorColor="primary"
        >
          <Tab
            label="Actividad"
            {...a11yProps(0)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
            }}
          />
          <Tab
            label="Servicios"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              display: loggedUser?.virtualizedDashboard ? 'none' : 'block',
            }}
          />
          <Tab
            label="Demográfico"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
            }}
          />
          <Tab
            label="Salario bajo demanda"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
            }}
          />
          <Tab
            label="Salud"
            {...a11yProps(1)}
            style={{
              color: '#2c3e50',
              fontSize: '18px',
              fontWeight: '600',
              display: loggedUser?.virtualizedDashboard ? 'none' : 'block',
            }}
          />
        </Tabs>
      </Box>
      {loading && <LinearProgressStyled color="info" />}
      <TabPanel value={tabValue} index={0}>
        <StyledChartContainer>
          <ChartTitle variant="h5" noWrap>
            Actividad
          </ChartTitle>
          <Container>
            <StyledUpperChartCardBox>
              <StyledChartCardBox style={{ marginRight: '10px' }}>
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                    marginBottom: '0px',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: 'rgba(77,87,115,255)',
                      fontSize: '55px',
                      fontWeight: '900',
                    }}
                  >
                    {!loading ? loginByYear : <Skeleton style={{
                      width: '50px',
                      height: '68px',
                    }} />}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: 'rgba(77,87,115,255)', fontSize: '20px', marginTop: '20px' }}
                  >
                    Inicios de sesión únicos durante el periodo {new Date().getFullYear()}
                  </Typography>
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox style={{ marginRight: '10px' }}>
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '20px',
                    marginBottom: '0px',
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      color: 'rgba(77,87,115,255)',
                      fontSize: '55px',
                      fontWeight: '900',
                    }}
                  >
                    {!loading ? lastMonthLogins : <Skeleton style={{
                      width: '50px',
                      height: '68px',
                    }} />}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ color: 'rgba(77,87,115,255)', fontSize: '20px', marginTop: '20px' }}
                  >
                    Inicios de sesión únicos durante el periodo {lastMonthPeriod}
                  </Typography>
                </div>
              </StyledChartCardBox>
              {!loggedUser?.virtualizedDashboard &&
                <StyledChartCardBox style={{ marginRight: '10px' }}>
                  <div
                    style={{
                      padding: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '20px',
                      marginBottom: '0px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        color: 'rgba(77,87,115,255)',
                        fontSize: '55px',
                        fontWeight: '900',
                      }}
                    >
                      {!loading ? loginByYearTotals || 0 : <Skeleton style={{
                        width: '50px',
                        height: '68px',
                      }} />}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: 'rgba(77,87,115,255)', fontSize: '20px', marginTop: '20px' }}
                    >
                      Inicios de sesión totales durante el periodo {lastMonthPeriod}
                    </Typography>
                  </div>
                </StyledChartCardBox>
              }
            </StyledUpperChartCardBox>
            <StyledChartCardBox>
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
                marginTop={3}
                marginBottom={3}
              >
                <Grid item xs={10} md={4}>
                  <StyledCard sx={{ border: '10px solid #188754' }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ color: '#188754' }}
                      >
                        Colaboradores totales
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          color: '#188754',
                          fontSize: '50px',
                          fontWeight: '900',
                        }}
                      >
                        {!loading ? coveredEmployees : <Skeleton style={{
                          width: '50px',
                          height: '68px',
                        }} />}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
                <Grid item xs={10} md={4}>
                  <StyledCard sx={{ border: '10px solid #0e6efd' }}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ color: '#0e6efd' }}
                      >
                        Colaboradores activos
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{
                          color: '#0e6efd',
                          fontSize: '50px',
                          fontWeight: '900',
                        }}
                      >
                        {!loading ? enrolledEmployees : <Skeleton style={{
                          width: '50px',
                          height: '68px',
                        }} />}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            </StyledChartCardBox>
            {!loggedUser?.virtualizedDashboard &&
              <>
                <ChartTitle variant="h5" noWrap>
                  Actividad mensual {new Date().getFullYear()}
                </ChartTitle>
                <StyledChartCardBox
                  style={{
                    minHeight: '600px',
                  }}
                >
                  {dataMonthly && <Bar data={dataMonthly} options={optionsByMonthChart} />}
                </StyledChartCardBox>
              </>
            }
          </Container>
          {dataByArea &&
            <Container>
              <ChartTitle variant="h5" noWrap>
                Colaboradores por área
              </ChartTitle>
              <StyledChartCardBox style={{
                minHeight: '600px',
              }}>
                {dataByArea && <Bar options={optionsByAreaChart} data={dataByArea} />}
              </StyledChartCardBox>
            </Container>
          }
        </StyledChartContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Servicios
              </ChartTitle>
              <StyledChartCardBox
                style={{
                  minHeight: '600px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  marginTop={3}
                  marginBottom={3}
                >
                  <Grid item xs={12} md={4}>
                    <img
                      src={appImage}
                      alt="Logo Tewispi"
                      style={{
                        width: '100%',
                        maxWidth: '250px',
                        marginBottom: '30px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div>
                      {clicksChartData && <Bar data={clicksChartData} options={optionsServiceChart} />}
                    </div>
                  </Grid>
                </Grid>
              </StyledChartCardBox>
              {scoreInformation && <StyledChartCardBox style={{ textAlign: 'start' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography style={{ fontWeight: 'bold', color: '#666666', marginBottom: '20px', fontSize: '1rem' }}>Calificación
                    promedio en las consultas</Typography>
                  <StarScoreDashboard score={scoreInformation.averageScore || 0}></StarScoreDashboard>
                </div>
                <p style={{
                  marginTop: '30px',
                  fontSize: '1rem',
                  color: '#666666',
                  fontWeight: '500',
                }}>*Media de puntuaciones en consultas durante los últimos 12 meses.</p>
              </StyledChartCardBox>}
              {clicksByServiceData && <StyledChartCardBox>
                <div style={{ height: '700px', display: 'flex', justifyContent: 'center' }}>
                  <Doughnut data={clicksByServiceData} options={optionsDonutChart} />
                </div>
              </StyledChartCardBox>}
            </Container>
          </>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Demográfico
              </ChartTitle>
              <StyledChartCardBox>
                <div>
                  {companyHeadCountData && <Bar data={companyHeadCountData} options={optionsEmployeeChart} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox>
                <div>
                  {registerByMonthData && <Bar data={registerByMonthData} options={optionsRegisterEmployeesByMonth} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox>
                <div>
                  {deactivatedByMonthsData &&
                    <Bar data={deactivatedByMonthsData} options={optionsDeactivatedEmployeesByMonth} />}
                </div>
              </StyledChartCardBox>
              {dataPie &&
                <StyledChartCardBox style={{ padding: 0 }}>
                  <div style={{ height: '950px', display: 'flex', justifyContent: 'center' }}>
                    <Pie data={dataPie} options={optionsPieChart} />
                  </div>
                </StyledChartCardBox>
              }
              <StyledChartCardBox>
                <div>
                  {companyHeadCountByAgeData && <Bar data={companyHeadCountByAgeData} options={optionsEmployeeByAge} />}
                </div>
              </StyledChartCardBox>
            </Container>
          </>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Salario bajo demanda
              </ChartTitle>
              <StyledChartCardBox>
                <div>
                  {moneyAmountDataChart && <Line data={moneyAmountDataChart} options={totalAmountMoneyChart} />}
                </div>
              </StyledChartCardBox>
              <StyledChartCardBox>
                <div>
                  {transactionAmountDataChart &&
                    <Bar data={transactionAmountDataChart} options={transactionAmountChart} />}
                </div>
              </StyledChartCardBox>
            </Container>
          </>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        {!loading && (
          <>
            <Container>
              <ChartTitle variant="h5" noWrap>
                Salud
              </ChartTitle>
              {dataCountConsultingReason &&
                <StyledChartCardBox style={{
                  minHeight: '600px',
                }}>
                  <Bar options={optionsConsultingChart} data={dataCountConsultingReason} />
                </StyledChartCardBox>
              }
              {dataPieSpecialities &&
                <StyledChartCardBox style={{ padding: 0 }}>
                  <div style={{ height: '750px', display: 'flex', justifyContent: 'center' }}>
                    <Pie data={dataPieSpecialities} options={optionsPieChartSpecialities} />
                  </div>
                </StyledChartCardBox>
              }
              {specialityCountDataChart &&
                <StyledChartCardBox>
                  <div>
                    <Line data={specialityCountDataChart} options={specialitiesChart} />
                  </div>
                </StyledChartCardBox>
              }
              {dataSpecialitiesLastMonth &&
                <StyledChartCardBox style={{ padding: 0 }}>
                  <div style={{ height: '900px', display: 'flex', justifyContent: 'center' }}>
                    <Doughnut data={dataSpecialitiesLastMonth} options={optionsChartReasonsLastMonth} />
                  </div>
                </StyledChartCardBox>
              }
              <StyledChartCardBox>
                <div>
                  <ReasonsLastTwelveMonthsDashboard />
                </div>
              </StyledChartCardBox>
            </Container>
          </>
        )}
      </TabPanel>
    </RootBox>
  );
};

export default Dashboard;
