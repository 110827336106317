import { moneyFormat, trimStringToMaxLength } from '../../utilities/i18n/helpers';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);
if (Chart.defaults.plugins.datalabels) {
  Chart.defaults.plugins.datalabels.display = false;
}

const legendMarginRight = {
  id: 'legendMarginRight',
  afterInit(chart: any) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      const width = this.width += 50;
      return width;
    };
  },
};

Chart.register(legendMarginRight);

const maxNumberOutsideChart = 5;

const formatDataLabels = (value: any, ctx: any): string => {
  let sum = 0;
  const dataArr = ctx.chart.data.datasets[0].data;
  dataArr.map((data: any) => {
    sum += parseInt(data);
  });
  if (value === 0) {
    return '';
  }
  const percentage = (value * 100 / sum).toFixed(1);
  if (parseFloat(percentage) < 2) {
    return '';
  }
  return percentage + '%';
};

const formatTooltip = (tooltipItem: any) => {
  const value = tooltipItem.raw;
  if (isNaN(value) || value === null || value === undefined) {
    return `${tooltipItem.dataset.label}: Valor no disponible`;
  }
  const dataArr = tooltipItem.chart.data.datasets[0].data;
  const sum = dataArr.reduce((acc: number, currentValue: any) => {
    const numericValue = parseFloat(currentValue);
    return !isNaN(numericValue) ? acc + numericValue : acc;
  }, 0);
  if (sum === 0) {
    return `${tooltipItem.dataset.label}: Sin datos válidos`;
  }
  const percentage = ((value * 100) / sum).toFixed(1);
  return `${tooltipItem.dataset.label}: ${value} / ${percentage}%`;
};

const alignDataLabels = (context: any) => {
  const value = context.dataset.data[context.dataIndex];
  let sum = 0;
  context.dataset.data.forEach((data: any) => {
    sum += parseInt(data);
  });
  const percentage = (value * 100 / sum);
  return percentage < maxNumberOutsideChart ? 'end' : 'center';
};

const setAnchorDataLabels = (context: any) => {
  const value = context.dataset.data[context.dataIndex];
  let sum = 0;
  context.dataset.data.forEach((data: any) => {
    sum += parseInt(data);
  });
  const percentage = (value * 100 / sum);
  return percentage < maxNumberOutsideChart ? 'end' : 'center';
};

const setOffsetDataLabels = (context: any) => {
  const value = context.dataset.data[context.dataIndex];
  let sum = 0;
  context.dataset.data.forEach((data: any) => {
    sum += parseInt(data);
  });
  const percentage = (value * 100 / sum);
  return percentage < maxNumberOutsideChart ? 8 : 0;
};

export const optionsByMonthChart = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Inicios de sesión totales por mes',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
  },
};

export const optionsServiceChart = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Servicios utilizados durante ' + new Date().getFullYear(),
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
    },
  },
};

export const optionsEmployeeChart = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Cantidad de colaboradores durante los últimos 12 meses',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      callbacks: {
        beforeBody: function(tooltipItems: any) {
          let total = 0;
          const stacks = tooltipItems[0].parsed._stacks.y;
          Object.keys(stacks)
            .filter((key) => !key.startsWith('_'))
            .forEach((key) => {
              const value = stacks[key];
              if (typeof value === 'number') {
                total += value;
              }
            });
          return 'Total del mes: ' + total;
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

export const optionsEmployeeByAge = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Cantidad de colaboradores por antigüedad',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

export const optionsRegisterEmployeesByMonth = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Cantidad de colaboradores nuevos durante los últimos 12 meses',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

export const optionsDeactivatedEmployeesByMonth = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Cantidad de colaboradores que se dieron de baja durante los últimos 12 meses',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      callbacks: {
        beforeBody: function(tooltipItems: any) {
          let total = 0;
          const stacks = tooltipItems[0].parsed._stacks.y;
          Object.keys(stacks)
            .filter((key) => !key.startsWith('_'))
            .forEach((key) => {
              const value = stacks[key];
              if (typeof value === 'number') {
                total += value;
              }
            });
          return 'Total del mes: ' + total;
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

export const optionsByAreaChart = {
  plugins: {
    title: {
      display: true,
      text: 'Inicios de sesión por áreas',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        precision: 0,
      },
    },
  },
};

export const optionsConsultingChart = {
  plugins: {
    title: {
      display: true,
      text: 'Consultas realizadas durante los últimos 12 meses',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        precision: 0,
      },
    },
  },
};

const getPosition = (): 'top' | 'right' | 'center' | 'bottom' | 'left' | 'chartArea' => {
  return 'right';
};

const getPositionText = (): 'right' | 'center' | 'left' => {
  return 'left';
};

export const optionsPieChart = {
  layout: {
    padding: 60,
  },
  plugins: {
    legend: {
      position: getPosition(),
      rtl: true,
      labels: {
        textAlign: getPositionText(),
      },
    },
    title: {
      display: true,
      text: 'Cantidad de colaboradores por áreas',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    tooltip: {
      callbacks: {
        label: formatTooltip
      },
    },
    datalabels: {
      display: true,
      color: 'rgba(76, 86, 115, 255)',
      font: {
        size: 17,
        family: 'Arial',
        weight: 600,
      },
      formatter: formatDataLabels,
      align: alignDataLabels,
      anchor: setAnchorDataLabels,
      offset: setOffsetDataLabels,
    },
  },
};

export const optionsPieChartSpecialities = {
  layout: {
    padding: 30,
  },
  plugins: {
    legend: {
      position: getPosition(),
      rtl: true,
      labels: {
        textAlign: getPositionText(),
      },
    },
    title: {
      display: true,
      text: 'Especialidades consultadas durante el mes anterior',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    tooltip: {
      callbacks: {
        label: formatTooltip
      },
    },
    datalabels: {
      display: true,
      color: 'rgba(76, 86, 115, 255)',
      font: {
        size: 17,
        family: 'Arial',
        weight: 600,
      },
      formatter: formatDataLabels,
      align: alignDataLabels,
      anchor: setAnchorDataLabels,
      offset: setOffsetDataLabels,
    },
  },
};

export const optionsDonutChart = {
  plugins: {
    legend: {
      position: getPosition(),
      rtl: true,
      labels: {
        textAlign: getPositionText(),
        filter: (legendItem: any) => {
          legendItem.text = trimStringToMaxLength(legendItem.text, 55);
          return true;
        },
      },
    },
    title: {
      display: true,
      text: 'Cantidad de veces que se utilizó cada servicio en el mes actual',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    tooltip: {
      callbacks: {
        label: formatTooltip
      },
    },
    datalabels: {
      display: true,
      color: 'rgba(76, 86, 115, 255)',
      font: {
        size: 17,
        family: 'Arial',
        weight: 600,
      },
      formatter: formatDataLabels,
      align: alignDataLabels,
      anchor: setAnchorDataLabels,
      offset: setOffsetDataLabels,
    },
  },
};

export const optionsChartReasonsLastMonth = {
  layout: {
    padding: 30,
  },
  plugins: {
    legend: {
      position: getPosition(),
      rtl: true,
      labels: {
        textAlign: getPositionText(),
      },
    },
    title: {
      display: true,
      text: 'Motivos de consulta durante el mes anterior',
      padding: 0,
      font: {
        size: 16,
      },
    },
    tooltip: {
      callbacks: {
        label: formatTooltip
      },
    },
    datalabels: {
      display: true,
      color: 'rgba(76, 86, 115, 255)',
      font: {
        size: 17,
        family: 'Arial',
        weight: 600,
      },
      formatter: formatDataLabels,
      align: alignDataLabels,
      anchor: setAnchorDataLabels,
      offset: setOffsetDataLabels,
    },
  },
};

export const backgroundColors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(155, 82, 152, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(201, 203, 207, 0.2)',
  'rgba(255, 105, 180, 0.2)',
  'rgba(255, 215, 0, 0.2)',
  'rgba(255, 69, 0, 0.2)',
  'rgba(138, 43, 226, 0.2)',
];

export const backgroundColorsTransparent = [
  'rgba(255, 206, 86, 0.1)',
  'rgba(255, 99, 132, 0.1)',
  'rgba(54, 162, 235, 0.1)',
  'rgba(75, 192, 192, 0.1)',
  'rgba(153, 102, 255, 0.1)',
  'rgba(155,82,152,0.1)',
];

export const backgroundColorsChart = [
  'rgba(255, 206, 86, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(155,82,152,1)',
];

export const transactionAmountChart = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Cantidad de transacciones durante los últimos 12 meses',
      padding: {
        bottom: 30,
      },
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

const moneyTooltipCallback = (tooltipItem: any) => {
  return `Cantidad de dinero: ${moneyFormat(tooltipItem.raw)}`;
};

export const totalAmountMoneyChart = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Valor monetario operado durante los últimos 12 meses',
      font: {
        size: 16,
      },
    },
    tooltip: {
      callbacks: {
        label: moneyTooltipCallback,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value: string | number) => `${moneyFormat(value)}`,
      },
    },
  },
};

export const specialitiesChart = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Especialidades médicas consultadas durante los últimos 12 meses',
      font: {
        size: 16,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
    },
  },
  elements: {
    line: {
      fill: 'start',
    },
  },
};

export const reasonsChart = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Motivos de consulta durante los últimos 12 meses',
      font: {
        size: 16,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      stacked: true,
    },
  },
  elements: {
    line: {
      fill: 'start',
    },
  },
};